import request from '@/tools/request'

const prefix = 'articles-draft'

export const createArticleRawRequest = (data) => {
    return request({
        method: 'POST',
        url: `${prefix}/`,
        data
    });
};

export const getAllArticlesRawRequest = () => {
    return request({
        method: 'GET',
        url: `${prefix}`
    });
};

export const getArticleRawRequest = (id) => {
    return request({
        method: 'GET',
        url: `${prefix}/${id}`
    });
};

export const updateArticleRawRequest = (data, id) => {
    return request({
        method: 'PATCH',
        url: `${prefix}/${id}`,
        data
    });
};

export const deleteArticleRawRequest = (id) => {
    return request({
        method: 'DELETE',
        url: `${prefix}/${id}`
    });
};
