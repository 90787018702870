import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VuetifyConfirm from './confirm'

Vue.use(Vuetify);

const vuetify = new Vuetify({
});

// Install confirmation dialog plugin
Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
