import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import moment from 'moment';
import store from './store'
import VueClipboard from 'vue-clipboard2'

// Vue plugins
import '@/plugins/lprogress'
import '@/plugins/transEntity'
import '@/plugins/Auth'
import '@/plugins/FormattedDateFilter'
import '@/plugins/moment'

Vue.prototype.$moment = moment;

Vue.use(VueClipboard)



Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
