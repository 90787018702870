import Vue from 'vue'
import store from '@/store'

export const LProgress = Object.freeze({
	isLoading: function () {
		return store.getters.loading
	},
	begin: function () {
		store.dispatch('setLoading', true)
	},
	end: function () {
		store.dispatch('setLoading', false)
	},
	messageDisabled: function () {
		store.commit('setMessageDisabled', true)
	},
	messageEnabled: function () {
		store.commit('setMessageDisabled', false)
	},
	isShowMessage: function() {
		return !store.getters.messageDisabled
	},
	isHideMessage: function() {
		return store.getters.messageDisabled
	},
	message: function (message) {
		store.dispatch('setMessage', message)
	},
	error: function (message) {
		store.dispatch('setErrorMessage', message)
	}
})

const LProgressPlugin = {
	install(Vue) {
		Vue.prototype.$lprogress = LProgress
	}
}

Vue.use(LProgressPlugin)
