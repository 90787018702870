import {
    getAllNewsRequest,
    createNewsRequest,
    updateNewsRequest,
    deleteNewsRequest,
} from '@/api/news';
import {
    createNewsSummaryRequest,
    deleteNewsSummaryRequest,
    updateNewsSummaryRequest
} from '@/api/news-summary'

export default {
    state: {
        news: [],
    },
    getters: {
        news(state) {
            return state.news;
        },
    },
    mutations: {
        setNews(state, payload) {
            state.news = payload;
        },
        addNews(state, payload) {
            state.news.push(payload);
        },
        delNews(state, payload) {
            state.news = state.news.filter(news => news.postId !== payload);
        },
    },
    actions: {
        // News actions begins
        async fetchNews ({commit}) {
            commit('setLoading', true);
            try {
                const res = await getAllNewsRequest();
                commit('setNews', res.data.reverse());
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        },
        async createNews ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await createNewsRequest(payload);
                commit('addNews', res.data.data);

                // Create summary variant
                payload.commentsQuantity = 0
                await createNewsSummaryRequest(payload)

                await dispatch('deleteNewsRaw', payload.postId)
                await dispatch('setMessage', res.data.message);

            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async updateNews ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await updateNewsRequest(payload.postId, payload);

                // Update summary variant
                await updateNewsSummaryRequest(payload.postId, {
                    slug: payload.slug ,
                    title: payload.title ,
                    rubric: payload.rubric ,
                    excerpt: payload.excerpt ,
                    ageRestrictions: payload.ageRestrictions ,
                    publicationStatus: payload.publicationStatus
                })

                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async deleteNews ({commit, dispatch}, id) {
            commit('setLoading', true);
            try {
                const res = await deleteNewsRequest(id);

                // Delete summary variant
                await deleteNewsSummaryRequest(id)

                commit('delNews', id);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        // News actions ends
    },
}
