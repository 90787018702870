<template>
    <v-dialog eager @input="change" value="true" :max-width="width" :persistent="persistent"
              @keydown.esc="choose(false)">
        <v-card tile>
            <v-card-title v-if="Boolean(title)" class="justify-center flex-column pt-6 pb-0">
                <v-icon
                    v-if="Boolean(icon)"
                    :color="color"
                    large
                    class="mb-3"
                >
                    {{ icon }}
                </v-icon>
                <div class="text-center" style="word-break:break-word">
                    {{ title }}
                </div>
            </v-card-title>
            <v-card-text class="py-4 text-center body-1" style="width: 100%" v-html="message"/>
            <v-card-actions class="d-flex flex-column-reverse flex-md-row justify-center pb-8 mt-4">
                <v-btn
                    :color="buttonFalseColor"
                    :small="!!actions"
                    @click="choose(false)"
                    class="mr-md-2 mt-2 mt-md-0"
                >
                    {{ buttonFalseText }}
                </v-btn>
                <v-spacer class="d-none d-md-block"/>
                <template
                    v-if="actions"
                >
                    <div class="d-flex justify-center align-center flex-sm-row flex-column">
                        <v-btn
                            v-for="(btn, index) in actions"
                            :key="index"
                            v-bind="btn.props"
                            small
                            @click="choose(btn.value)"
                            class="ma-1"
                        >
                            {{ btn.text }}
                        </v-btn>
                    </div>
                </template>
                <v-btn
                    v-else
                    :color="buttonTrueColor"
                    @click="choose(true)"
                    class="px-4"
                >
                    {{ buttonTrueText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        buttonTrueText: {
            type: String,
            default: 'Ok'
        },
        buttonFalseText: {
            type: String,
            default: 'Cancel'
        },
        buttonTrueColor: {
            type: String,
            default: 'primary'
        },
        buttonFalseColor: {
            type: String,
            default: null
        },
        buttonFalseFlat: {
            type: Boolean,
            default: true
        },
        buttonTrueFlat: {
            type: Boolean,
            default: true
        },
        actions: {
            type: Array,
            default: null
        },
        color: {
            type: String,
            default: 'warning'
        },
        icon: {
            type: String,
            default: 'mdi-alert'
        },
        message: {
            type: String,
            required: true
        },
        persistent: Boolean,
        title: {
            type: String
        },
        width: {
            type: Number,
            default: 450
        }
    },
    data() {
        return {
            value: false
        }
    },
    mounted() {
        document.addEventListener('keyup', this.onEnterPressed)
    },
    destroyed() {
        document.removeEventListener('keyup', this.onEnterPressed)
    },
    methods: {
        onEnterPressed(e) {
            if (e.keyCode === 13) {
                e.stopPropagation()
                this.choose(true)
            }
        },
        choose(value) {
            this.$emit('result', value)
            this.value = value
            this.$destroy()
        },
        change() {
            this.$destroy()
        }
    }
}
</script>
