import {
    createNewsRawRequest,
    getAllNewsRawRequest,
    updateNewsRawRequest,
    deleteNewsRawRequest
} from '@/api/news-raw';
import { PUBLICATION_SAVED_AS_AUTHOR_DRAFT } from '@/constants/publication-status';

export default {
    state: {
        newsPending: [],
    },
    getters: {
        newsPending(state) {
            return state.newsPending;
        },
    },
    mutations: {
        setNewsPending(state, payload) {
            state.newsPending = payload;
        },
        addNewsPending(state, payload) {
            state.newsPending.push(payload);
        },
        delNewsPending(state, payload) {
            state.newsPending = state.newsPending.filter(news => news.postId !== payload);
        },
    },
    actions: {
        async fetchNewsRaw ({commit}) {
            commit('setLoading', true);
            try {
                const res = await getAllNewsRawRequest();
                const parsedData = res.data.filter(item => item.publicationStatus !== PUBLICATION_SAVED_AS_AUTHOR_DRAFT);
                commit('setNewsPending', parsedData);
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        },
        async createNewsRaw ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await createNewsRawRequest(payload);
                commit('addNewsPending', res.data.data);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async updateNewsRaw ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await updateNewsRawRequest(payload, payload.postId);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async deleteNewsRaw ({commit, dispatch}, id) {
            commit('setLoading', true);
            try {
                const res = await deleteNewsRawRequest(id);
                commit('delNewsPending', id);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
    },
}
