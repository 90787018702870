import request from '@/tools/request'

const prefix = 'app/initialize'

export const fetchRoles = () => {
	return request({
		method: 'GET',
		url: `${prefix}/roles`
	})
}

export const fetchAppUsers = (params) => {
	return request({
		method: 'GET',
		url: `${prefix}/users`,
		params
	})
}
