<template>
    <component
        :is="resolveLayoutVariant"
    >
        <router-view></router-view>
    </component>
</template>

<script>
    export default {
        name: 'App',

        components: {
            'layout-blank': () => import('@/components/Common/Layouts/LayoutBlank'),
            'layout-content': () => import('@/components/Common/Layouts/LayoutContent')
        },
        data() {
            return {
                drawer: null
            };
        },
        computed: {
            resolveLayoutVariant() {
                if (this.$route.meta.layout === 'blank') return 'layout-blank'

                return `layout-content`
            }
        }
    }
</script>
