import request from '@/tools/request'
import api from "../config/api";

const prefix = 'news'

export const getNewsPublicPath = (slug) => {
    return `${ api.MEDIA_FRONTEND_URL }/news/${ slug }`
}

export const getNewsRequest = (postId) => {
    return request({
        method: 'GET',
        url: `${prefix}/${postId}`
    });
};

export const getNewsCollection = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}/collection`,
        params
    });
};

export const getAllNewsRequest = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}/all`,
        params
    });
};

export const createNewsRequest = (data, options) => {
    return request({
        method: 'POST',
        url: `${prefix}/`,
        ...options,
        data
    });
};

export const updateNewsRequest = (postId, data, options) => {
    return request({
        method: 'PATCH',
        url: `${prefix}/${postId}`,
        ...options,
        data
    });
};

export const deleteNewsRequest = (postId, options) =>  {
    return request({
        method: 'DELETE',
        url: `${prefix}/${postId}`,
        ...options
    });
};
