import request from '@/tools/request'

const prefix = 'articles-summary'

export const createArticleSummaryRequest = (data, options) => {
	return request({
		method: 'POST',
		url: `${prefix}`,
		...options,
		data
	})
}

export const updateArticleSummaryRequest = (postId, data, options) => {
	return request({
		method: 'PATCH',
		url: `${prefix}/${postId}`,
		...options,
		data
	})
}

export const deleteArticleSummaryRequest = (postId, options) => {
	return request({
		method: 'DELETE',
		url: `${prefix}/${postId}`,
		...options
	})
}
