import Cookies from 'js-cookie'

const ACCESS_TOKEN_KEY = 'connect.atid'
const REFRESH_TOKEN_KEY = 'connect.rtid'
const SESSION_ID_KEY = 'mbo.sid'

export default {
    setAccessToken(token) {
        Cookies.set(ACCESS_TOKEN_KEY, token)
    },

    setRefreshToken(token) {
        Cookies.set(REFRESH_TOKEN_KEY, token)
    },

    setSessionId(sessionId) {
        Cookies.set(SESSION_ID_KEY, sessionId)
    },

    getAccessToken() {
        const token = Cookies.get(ACCESS_TOKEN_KEY)

        return token !== null && token !== 'null' &&
        token !== undefined && token !== 'undefined'
            ? token
            : null
    },

    getRefreshToken() {
        return Cookies.get(REFRESH_TOKEN_KEY)
    },

    getSessionId() {
        return Cookies.get(SESSION_ID_KEY)
    },

    decodeAccessToken(token) {
        return token ? JSON.parse(atob(token.split('.')[1])) : null
    },

    removeAccessToken() {
        Cookies.remove(ACCESS_TOKEN_KEY)
    },

    removeRefreshToken() {
        Cookies.remove(REFRESH_TOKEN_KEY)
    },

    removeSessionId() {
        Cookies.remove(SESSION_ID_KEY)
    }
}
