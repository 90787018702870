import { getAuthUser, authLogin, authLogout } from '@/api/auth'
import Vue from 'vue'
import token from '@/tools/token'
import { LProgress } from '@/plugins/lprogress'
import utils from '@/tools/utils'

export default {
    state: {
        user: null
    },
    getters: {
        authSid: state => state.user ? state.user.sessionId : undefined,
        user: state => state.user,
        userName: state => {
            const user = state.user

            if (!user) return null

            if (utils.isNotEmpty(user.firstName) && utils.isNotEmpty(user.lastName)) {
                return `${ user.firstName } ${ user.lastName }`
            }

            let nick = user.nick

            if (!nick) {
                const email = user.email.split('@')

                nick = email[0]
            }

            return nick
        }
    },
    mutations: {
        SET_AUTH_USER(state, payload) {
            Vue.set(state, 'user', payload)
        }
    },
    actions: {
        async signIn({ dispatch }, payload) {
            LProgress.begin()

            try {
                //-------
                const response = await authLogin(payload)
                const data = response.data

                token.setAccessToken(data['accessToken'])
                token.setRefreshToken(data['refreshToken'])

                await dispatch('fetchAuthUser')
                //-------

                // await authLogin(payload)
                // await dispatch('fetchAuthUser')

                LProgress.end()

            } finally {
                LProgress.end()
            }
        },
        async signOut({ commit, state }) {
            try {
                const userId = state.user ? state.user.userId : null

                // ---------
                const payload = {
                    refreshToken: token.getRefreshToken(),
                    accessToken: token.getAccessToken(),
                    userId
                }

                await authLogout(payload)
                await token.removeAccessToken()
                await token.removeRefreshToken()
                // ---------


                // await authLogout({userId})
                // await token.removeAccessToken()

                commit('SET_AUTH_USER', null)
            } catch (error) {
                console.log(error)
            }
        },
        async fetchAuthUser({ commit }) {
            try {
                const response = await getAuthUser()
                commit('SET_AUTH_USER', response.data)
            } catch (error) {
                console.log(error)
            }

        }
    }
}
