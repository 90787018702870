import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export const VIEW_ACTION = 'view'
export const CREATE_ACTION = 'create'
export const UPDATE_ACTION = 'update'
export const DELETE_ACTION = 'delete'

const _DISABLE_CHECK = false

/**
 * Plugin for manage and getting information
 * of authenticated employee
 *
 * ---------------------------
 * Use in component:
 * ---------------------------
 * this.$auth.can() the the auth employee can execute action with current admission
 *
 * @type {{install(*): void}}
 */

export const Auth =  Object.freeze({
	VIEW_ACTION: VIEW_ACTION,
	CREATE_ACTION: CREATE_ACTION,
	UPDATE_ACTION: UPDATE_ACTION,
	DELETE_ACTION: DELETE_ACTION,

	/**
	 * Get Current auth employee
	 *
	 * @returns {Object}
	 */
	auth: () => {
		return store.getters.user
	},

	acceptFrom(userId) {
		if(!userId) return false

		if(!Auth.auth()) return false

		return userId === Auth.auth().userId
	},

	/**
	 * Check if the auth employee
	 * can execute action with current admission
	 *
	 * @param {String} admission
	 * @param {String} action
	 * @returns {boolean}
	 */
	can: function (admission, action) {
		if(_DISABLE_CHECK) {
			return true
		}

		const permissions = store.getters.user
			? store.getters.user.permissions
			: null

		if(!permissions) {
			return false
		}

		// console.log('Auth.can()', admission, action)
		return permissions[admission] && permissions[admission][action]
			? permissions[admission][action]
			: false
	},

	/**
	 * Check if the auth employee
	 * cannot execute action with current admission
	 *
	 * @param {String} admission
	 * @param {String} action
	 * @returns {boolean}
	 */
	canNot: function(admission, action) {
		return !Auth.can(admission, action)
	},

	/**
	 * Faster checker, need only action
	 * NOTE: Use only through component, because need access to current route.
	 * It try to get meta parameter such as 'admission' of current route
	 *
	 * @param {String} action
	 * @returns {boolean}
	 */
	ability: function (action) {
		if(_DISABLE_CHECK) {
			return true
		}

		const admission = router.history.current.meta.admission
			? router.history.current.meta.admission
			: null

		// console.log('Auth.ability()', admission, action)

		return Auth.can(admission, action)
	},

	notAbility: function (action) {
		return !Auth.ability(action)
	},

	/**
	 *
	 * @returns {Object}
	 */
	user() {
		return store.getters.user
	},

	avatarUrl() {
		return store.getters.user.avatarUrl
	},

	name() {
		return store.getters.userName
	},

	email() {
		return store.getters.user.email
	},

	role() {
		return store.getters.user.role
	},

	userId() {
		return store.getters.user.userId
	},

	/**
	 *
	 * @returns {boolean}
	 */
	isAuthenticated() {
		return !!store.getters.user
	}
})

const AuthPlugin = {
	install(Vue) {
		Vue.prototype.$auth = Auth
	}
}

Vue.use(AuthPlugin)
