import {
    createArticleRawRequest,
    getAllArticlesRawRequest,
    updateArticleRawRequest,
    deleteArticleRawRequest
} from '@/api/articles-raw';
import { PUBLICATION_SAVED_AS_AUTHOR_DRAFT } from '@/constants/publication-status';

export default {
    state: {
        articlesPending: [],
    },
    getters: {
        articlesPending(state) {
            return state.articlesPending;
        },
    },
    mutations: {
        setArticlesPending(state, payload) {
            state.articlesPending = payload;
        },
        addArticlePending(state, payload) {
            state.articlesPending.push(payload);
        },
        delArticlePending(state, payload) {
            state.articlesPending = state.articlesPending.filter(article => article.postId !== payload);
        },
    },
    actions: {
        async fetchArticlesRaw ({commit}) {
            commit('setLoading', true);
            try {
                const res = await getAllArticlesRawRequest();
                const parsedData = res.data.filter(item => item.publicationStatus !== PUBLICATION_SAVED_AS_AUTHOR_DRAFT);
                commit('setArticlesPending', parsedData);
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        },
        async createArticleRaw ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await createArticleRawRequest(payload);
                commit('addArticlePending', res.data.data);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async updateArticleRaw ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await updateArticleRawRequest(payload, payload.postId);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async deleteArticleRaw ({commit, dispatch}, id) {
            commit('setLoading', true);
            try {
                const res = await deleteArticleRawRequest(id);
                commit('delArticlePending', id);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
    },
}
