// export default {
//     MEDIA_API_URL: 'http://localhost:10001',
//     MEDIA_FILE_API_URL: 'http://localhost:10003',
//     COMMENTS_API_URL: 'http://localhost:10002',
//     MEDIA_FRONTEND_URL: 'http://localhost:3000',
//     CHAT_SERVICE_SOCKET_URL: 'http://localhost:10004',
//     CHAT_SERVICE_API_URL: 'http://localhost:10004',
//     ACCOUNT_SERVICE_URL: 'http://localhost:16016'
// }

export default {
    MEDIA_API_URL: 'https://media-api.ask4games.com',
    MEDIA_FILE_API_URL: 'https://media-file-api.ask4games.com',
    COMMENTS_API_URL: 'https://comments-api.ask4games.com',
    MEDIA_FRONTEND_URL: 'https://ask4games.com',
    CHAT_SERVICE_SOCKET_URL: 'https://chat-service.ask4games.com',
    CHAT_SERVICE_API_URL: 'https://chat-service.ask4games.com',
    ACCOUNT_SERVICE_URL: 'https://account-service.ask4games.com'
}
