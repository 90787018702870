import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import chat from './chat'
import common from './common'
import articlesRaw from './articles-raw'
import articles from './articles'
import articleTypes from './articleTypes'
import newsRaw from './news-raw'
import news from './news'
import tags from './tags'
import admission from './admission'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: { auth, admission, chat, common, articlesRaw, articles, articleTypes, news, tags, newsRaw }
})
