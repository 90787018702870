import request from '@/tools/request'

const prefix = 'news-summary'

export const createNewsSummaryRequest = (data, options) => {
	return request({
		method: 'POST',
		url: `${prefix}`,
		...options,
		data
	})
}

export const updateNewsSummaryRequest = (postId, data, options) => {
	return request({
		method: 'PATCH',
		url: `${prefix}/${postId}`,
		...options,
		data
	})
}

export const deleteNewsSummaryRequest = (postId, options) => {
	return request({
		method: 'DELETE',
		url: `${prefix}/${postId}`,
		...options
	})
}
