/* eslint-disable */

const isNotEmpty = (param) => {
	return param !== null
		&& param !== undefined
		&& param !== 'null'
		&& param !== 'undefined'

}

const isEmpty = (param) => {
	return !isNotEmpty(param)
}

const isFunction = (param) => {
	return isNotEmpty(param)
		&& typeof param === 'function'
}

const isObject = (param) => {
	return typeof param === 'object'
		&& !Array.isArray(param)
}

const isArray = (param) => {
	return Array.isArray(param)
}

const isString = (param) => {
	return typeof param === 'string' || param instanceof String
}

function random(min, max) {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min)) + min
}

function upperFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const isAvailable = param => {
	return param !== null &&
		param !== 'null' &&
		param !== undefined &&
		param !== 'undefined'
}

function get(object, path, def) {
	return (object = (path.split ? path.split('.') : path).reduce(function (obj, p) {
		return obj && obj[p]
	}, object)) === undefined ? def : object;
}

function set  (object, path, val, obj) {
	return ((path = path.split ? path.split('.') : path.slice(0)).slice(0, -1).reduce(function (obj, p) {
		return (!/^(__proto__|constructor|prototype)$/.test(p))? obj[p] = obj[p] || {} : {};
	}, obj = object)[path.pop()] = val), object;
}

const letterColor = letter => {
	letter = letter.toUpperCase()

	const alphabetLatin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	const alphabetCyrillic = 'АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'

	const isLatin = alphabetLatin.includes(letter)
	const isCyrillic = alphabetCyrillic.includes(letter)

	if(!isLatin && !isCyrillic) return 'deep-purple'

	const getColor = index => {
		const colors = ['indigo', 'deep-purple', 'teal', 'pink', 'orange']
		const contrast = ['darken-1', 'darken-2']
		const percent = Math.floor(100 / (33 / index))
		let colorIndex = 0
		let contrastIndex = 0

		if (percent >= 0) {
			colorIndex = 0
			contrastIndex = percent >= 10 ? 1 : 0
		}

		if (percent >= 20) {
			colorIndex = 1
			contrastIndex = percent >= 30 ? 1 : 0
		}

		if (percent >= 40) {
			colorIndex = 2
			contrastIndex = percent >= 50 ? 1 : 0
		}

		if (percent >= 60) {
			colorIndex = 3
			contrastIndex = percent >= 70 ? 1 : 0
		}

		if (percent >= 80) {
			colorIndex = 4
			contrastIndex = percent >= 90 ? 1 : 0
		}

		return colors[colorIndex] + ' ' + contrast[contrastIndex]
	}

	if(isLatin) {
		return getColor(alphabetLatin.indexOf(letter))
	}

	if(isCyrillic) {
		return getColor(alphabetCyrillic.indexOf(letter))
	}

	return 'deep-purple'
}

export default {
	isNotEmpty,
	isEmpty,
	isFunction,
	isObject,
	isArray,
	isString,
	random,
	upperFirst,
	isAvailable,
	get,
	set,
	letterColor
}
