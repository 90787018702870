export const LOGIN_TYPE = 'email'
export const AUTO_SAVE_INTERVAL = 30000

export const APP_TOKEN_KEY = 'x-app-token'
export const APP_TOKEN = 'media-backoffice'
export const APP_MULTI_SESSION_KEY = 'x-app-multi-session'
export const APP_MULTI_SESSION = 0

export default {
    LOGIN_TYPE,
    AUTO_SAVE_INTERVAL,
    APP_TOKEN_KEY,
    APP_TOKEN,
    APP_MULTI_SESSION_KEY,
    APP_MULTI_SESSION
}
