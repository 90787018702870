import Vue from 'vue'

export default {
    state: {
        isOpenChat: false,
        sender: null,
        recipient: null,
        convoOptions: null,
        sheetTitle: null,
        sheetAvatar: null
    },
    getters: {
        isOpenChat: state => state.isOpenChat,
        chatSender: state => state.sender,
        chatRecipient: state => state.recipient,
        chatConvoOptions: state => state.convoOptions,
        chatSheetTitle: state => state.sheetTitle,
        chatSheetAvatar: state => state.sheetAvatar
    },
    mutations: {
        SET_IS_OPEN_CHAT_STATE(state, flag) {
            Vue.set(state, 'isOpenChat', flag)
        },
        SET_CHAT_RECIPIENT(state, recipient) {
            let recipientData = null

            if(recipient !== null && typeof recipient === 'object') {
                const props = Object.keys(recipient)

                if(props.includes('userName')
                    && props.includes('email')
                    && props.includes('avatarUrl')
                ) {
                    recipientData = Object.assign({}, recipient)
                } else {
                    console.warn('Chat recipient must contains [userName, email, avatarUrl] props.')
                }
            }

            Vue.set(state, 'recipient', recipientData)
        },
        SET_CHAT_SENDER(state, sender) {
            let senderData = null

            if(sender !== null && typeof sender === 'object') {
                const props = Object.keys(sender)

                if(sender.userName
                    && sender.email
                    && props.includes('avatarUrl')
                ) {
                    senderData = Object.assign({}, sender)
                } else {
                    console.warn('Chat sender must contains [userName, email, avatarUrl] props.')
                }
            }

            Vue.set(state, 'sender', senderData)
        },
        SET_CHAT_CONVO_OPTIONS(state, options) {
            Vue.set(state, 'convoOptions', options)
        },
        SET_CHAT_SHEET_TITLE(state, title) {
            Vue.set(state, 'sheetTitle', title)
        },
        SET_CHAT_SHEET_AVATAR(state, sheetAvatar) {
            Vue.set(state, 'sheetAvatar', sheetAvatar)
        }
    },
    actions: {}
}
