import Vue from 'vue'
import moment from 'moment'

const FormattedDateFilter = {
	install(Vue) {
		Vue.filter('formattedDate', function (date, format) {
			format = !format ? 'YYYY-MM-DD HH:mm' : format
			
			return moment(date).format(format)
		})
	}
}

Vue.use(FormattedDateFilter)
