import { getArticleTypesCollection } from '@/api/articles-types'

export default {
    state: {
        items: [],
    },
    getters: {
        getArticleTypes(state) {
            return state.items;
        },
    },
    mutations: {
        SET_TYPES(state, payload) {
            state.items = [].concat(payload)
        },
    },
    actions: {
        async fetchArticleTypes({commit}) {
            commit('setLoading', true);
            try {
                const res = await getArticleTypesCollection()
                commit('SET_TYPES', res.data.data);
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        }
    },
}
