import Vue from 'vue'
import request from '@/tools/request'
import { fetchRoles } from '@/api/app'

export default {
	state: {
		actions: [],
		entities: [],
		defaultPermissions: {},
		roles: []
	},
	getters: {
		admissionEntities (state) {
			return state.entities
		},
		admissionActions (state) {
			return state.actions
		},
		defaultPermissions (state) {
			return state.defaultPermissions
		},
		roles: state => state.roles
	},
	mutations: {
		SET_ADMISSION(state, data) {
			Vue.set(state, 'entities', data.entities)
			Vue.set(state, 'actions', data.actions)
			Vue.set(state, 'defaultPermissions', data.defaultPermissions)
		},
		SET_ROLES(state, roles) {
			Vue.set(state, 'roles', roles)
		}
	},
	actions: {
		async fetchAdmission({ commit }) {
			try {
				const response = await request({
					method: 'GET',
					url: 'app/admission'
				})

				commit('SET_ADMISSION', response.data)

			} catch (error) {
				console.log(error)
			}
		},
		async fetchRoles({ commit }) {
			try {
				const response = await fetchRoles()

				commit('SET_ROLES', response.data.roles)

			} catch (error) {
				console.log(error)
			}
		}
	}
}
