import {
    getAllTagsRequest
} from '@/api/tags';

export default {
    state: {
        tags: [],
    },
    getters: {
        getTags(state) {
            return state.tags;
        },
    },
    mutations: {
        SET_TAGS(state, payload) {
            state.tags = [].concat(payload)
        },
    },
    actions: {
        async fetchTags({commit}) {
            commit('setLoading', true);
            try {
                const res = await getAllTagsRequest();
                commit('SET_TAGS', res.data);
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        }
    },
}
