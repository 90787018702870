import Vue from 'vue'
import VueRouter from 'vue-router'
import routeGuard from '@/router/routeGuard'

Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard/DashboardPage.vue')
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/Users/LoginPage'),
        meta: {
            layout: 'blank',
            title: 'Media Backoffice'
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/Users/UsersPage'),
        meta: {
            admission: 'user'
        }
    },
    {
        path: '/users/:userId',
        name: 'user-profile',
        component: () => import('@/views/Users/UserProfilePage'),
        meta: {
            admission: 'user'
        }
    },
    {
        path: '/roles',
        name: 'Roles',
        component: () => import('@/views/Users/RolesPage'),
        meta: {
            admission: 'role'
        }
    },
    {
        path: '/template',
        name: 'Template',
        component: () => import('@/views/Site/TemplatePage.vue'),
        meta: {
            admission: 'template'
        }
    },
    {
        path: '/banners',
        name: 'Banners',
        component: () => import('@/views/Site/BannersPage'),
        meta: {
            admission: 'banner'
        }
    },
    {
        path: '/backlink-providers',
        name: 'BacklinkProviders',
        component: () => import('@/views/Backlinks/BacklinkProvidersPage'),
        meta: {
            admission: 'backlink_provider'
        }
    },
    {
        path: '/backlink-sources',
        name: 'BacklinkSources',
        component: () => import('@/views/Backlinks/BacklinkSourcesPage.vue'),
        meta: {
            admission: 'backlink_source'
        }
    },
    {
        path: '/backlink-statistic',
        name: 'BacklinkStatistic',
        component: () => import('@/views/Backlinks/BacklinkStatisticPage'),
        meta: {
            admission: 'backlink_statistic'
        }
    },
    {
        path: '/backlink-log',
        name: 'BacklinkLog',
        component: () => import('@/views/Backlinks/BacklinkLogPage'),
        meta: {
            admission: 'backlink_log'
        }
    },
    {
        path: '/ratings',
        name: 'Ratings',
        component: () => import('@/views/Ratings/RatingsPage.vue'),
        meta: {
            admission: 'rating'
        }
    },
    {
        path: '/marketing',
        name: 'Marketing',
        component: () => import('@/views/Marketing/MarketingPage.vue'),
        meta: {
            admission: 'marketing'
        }
    },
    {
        path: '/analytics',
        name: 'Analytics',
        component: () => import('@/views/Analytics/AnalyticsPage.vue'),
        meta: {
            admission: 'analytic'
        }
    },
    {
        path: '/taxonomy/tags',
        name: 'tags',
        component: () => import('@/views/Taxonomy/TagsPage.vue'),
        meta: {
            admission: 'tag'
        }
    },
    {
        path: '/taxonomy/genres',
        name: 'genres',
        component: () => import('@/views/Taxonomy/GenresPage.vue'),
        meta: {
            admission: 'gender'
        }
    },
    {
        path: '/taxonomy/article-types',
        name: 'article-types',
        component: () => import('@/views/Taxonomy/ArticleTypesPage.vue'),
        meta: {
            admission: 'article_type'
        }
    },
    {
        path: '/taxonomy/esport-categories',
        name: 'esport-categories',
        component: () => import('@/views/Taxonomy/ESportCategoriesPage.vue'),
        meta: {
            admission: 'esport_category'
        }
    },
    {
        path: '/taxonomy/hardware-categories',
        name: 'hardware-categories',
        component: () => import('@/views/Taxonomy/HardwareCategoriesPage.vue'),
        meta: {
            admission: 'hardware_category'
        }
    },
    {
        path: '/moderation/comments',
        name: 'Comments',
        component: () => import('@/views/Moderation/CommentsPage'),
        meta: {
            admission: 'comment'
        }
    },
    {
        path: '/moderation/comments/archive',
        name: 'CommentsArchive',
        component: () => import('@/views/Moderation/CommentsArchivePage'),
        meta: {
            admission: 'comment'
        }
    },
    {
        path: '/moderation/articles/archive',
        name: 'ArticlesArchive',
        component: () => import('@/views/Moderation/ArticlesArchivePage'),
        meta: {
            admission: 'article'
        }
    },
    {
        path: '/moderation/articles',
        name: 'Articles',
        component: () => import('@/views/Moderation/ArticlesPage'),
        meta: {
            admission: 'article'
        }
    },
    {
        path: '/moderation/articles/drafts/:postId',
        name: 'ArticleDrafts',
        props: true,
        component: () => import('@/views/Moderation/ArticleDraftsPage'),
        meta: {
            admission: 'article'
        }
    },
    {
        path: '/moderation/articles/edit-article/:postId',
        props: true,
        name: 'articles-editor',
        component: () => import('@/components/Articles/ArticlesEditor'),
        meta: {
            admission: 'article'
        }
    },
    {
        path: '/moderation/news',
        name: 'News',
        component: () => import('@/views/Moderation/NewsPage'),
        meta: {
            admission: 'news'
        }
    },
    {
        path: '/moderation/news/archive',
        name: 'NewsArchive',
        component: () => import('@/views/Moderation/NewsArchivePage'),
        meta: {
            admission: 'news'
        }
    },
    {
        path: '/moderation/news/edit-news/:postId',
        props: true,
        name: 'news-editor',
        component: () => import('@/components/News/NewsEditor.vue'),
        meta: {
            admission: 'news'
        }
    },
    {
        path: '/moderation/news/drafts/:postId',
        name: 'NewsDrafts',
        props: true,
        component: () => import('@/views/Moderation/NewsDraftsPage'),
        meta: {
            admission: 'article'
        }
    },
    {
        path: '/preview/:postType/:postId',
        name: 'post-preview',
        props: true,
        meta: {},
        component: () => import('@/views/Dashboard/PostPreviewPage')
    },
    {
        path: '/error-403',
        name: 'error-403',
        component: () => import('@/views/Errors/Error403Page')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(routeGuard)

export default router
