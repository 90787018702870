import request from '@/tools/request'

const prefix = 'using-taxonomy'

export const fetchUsingTaxonomyPublicStack = () => {
	return request({
		method: 'GET',
		url: `${prefix}/public-stack`
	})
}

export const fetchUsingTaxonomyDraftStack = () => {
	return request({
		method: 'GET',
		url: `${prefix}/draft-stack`
	})
}
