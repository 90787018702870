/* eslint-disable */

import {LProgress} from '@/plugins/lprogress'
import router from '@/router'
import api from '@/config/api'
import token from '@/tools/token'
import store from '@/store'
import axios from 'axios'
import {APP_MULTI_SESSION, APP_MULTI_SESSION_KEY, APP_TOKEN, APP_TOKEN_KEY} from '@/config/constants'
import utils from '@/tools/utils'

const IGNORED_PATHS = [
    'signin',
    'account/refresh',
    'account/logout',
    'auth/refresh-token',
    'auth/sign-in',
    'auth/sign-out'
]

const authSignOut = async (store, router) => {
    await store.dispatch('signOut')

    const currentRouteName = router.history.current.name
    const signIntRouteName = 'Login'

    if (currentRouteName !== signIntRouteName) {
        await router.push({ name: signIntRouteName })
    }
}

const axiosConfig = {
    baseURL: api.MEDIA_API_URL,
    withCredentials: true,
    // timeout: 5000
    headers: {
        'x-app-assigned': 'mediabo',
        [APP_TOKEN_KEY]: APP_TOKEN,
        [APP_MULTI_SESSION_KEY]: APP_MULTI_SESSION
    }
}

const axiosIns = axios.create(axiosConfig)

// request interceptor
axiosIns.interceptors.request.use(
    async config => {
        const isIgnored = IGNORED_PATHS.some(path => config.url.includes(path))
        let accessToken = token.getAccessToken()
        let refreshToken = token.getRefreshToken()

        let isExpired = false

        if (accessToken) {
            const payload = token.decodeAccessToken(accessToken)
            isExpired = Date.now() >= payload.exp * 1000
        }

        if (isExpired && !isIgnored) {
            try {
                // ---------------------
                const refreshReq = axios.create(axiosConfig)

                const data = {
                    refreshToken: token.getRefreshToken(),
                    accessToken: token.getAccessToken()
                }

                const refreshRes = await refreshReq({
                    method: 'POST',
                    url: 'auth/refresh-token',
                    data
                })

                accessToken = refreshRes.data.accessToken
                refreshToken = refreshRes.data.refreshToken

                token.setAccessToken(accessToken)
                token.setRefreshToken(refreshToken)
                // ---------------------

                // const refreshReq = axios.create(axiosConfig)
                //
                // await refreshReq({
                // 	method: 'POST',
                // 	url: 'auth/refresh-token'
                // })
                //
                // accessToken = token.getAccessToken()

            } catch (err) {
                await authSignOut(store, router)

                return config
            }
        }

        if (accessToken) {
            config.headers['x-access-token'] = accessToken
        }

        return config
    },
    error => {
        // do something with request error
        // console.log(error) // for debug

        return Promise.reject(error)
    }
)

// response interceptor
axiosIns.interceptors.response.use(
    response => {

        const data = response.data
            ? response.data
            : {}

        // console.log('network/request.success: ', data) // For debug

        // Set message response if exist
        const message = data.message

        if (message && LProgress.isShowMessage()) {
            if (data.success === 0) {
                LProgress.error(message)
            } else {
                LProgress.message(message)
            }
        }

        if (LProgress.isHideMessage()) {
            LProgress.messageEnabled()
        }

        if (data.success === 0) {
            LProgress.end()

            return Promise.reject({
                message,
                response: { data }
            })
        }

        return response
    },
    async error => {
        // end loading progress
        LProgress.end()

        const res = error.response

        if (res && res.status === 403) {
            store.dispatch('fetchAuthUser')
                .then(() => {
                    const currentRouteName = router.history.current.name
                    const error403RouteName = 'error-403'

                    if (currentRouteName !== error403RouteName) {
                        router.push({ name: error403RouteName })
                    }
                })
        }

        const errorConfigUrl = utils.get(error, 'config.url', '')
        const isIgnored = IGNORED_PATHS.some(path => errorConfigUrl.includes(path))

        // console.log(res.status, error.config.url, res && res.status === 401 && !isIgnored)

        if (res && res.status === 401 && !isIgnored) {
            // console.log('error.config.retryAttempts', error.config.retryAttempts)

            if (error.config.retryAttempts) {
                // console.log('error.response')

                await authSignOut(store, router)

            } else {
                // console.log('error.response: resolve')

                const config = {
                    retryAttempts: 1,
                    ...error.config
                }

                return Promise.resolve(axiosIns(config))
            }
        }

        // console.log('network/request.error: ', res) // For debug

        let message = undefined

        if (!res) {
            message = error.message
        } else if (res.data.message) {
            message = res.data.message
        } else if (res.statusText) {
            message = res.statusText
        }

        if (message) {
            LProgress.error(message)
        }

        return Promise.reject(error)
    }
)

export default axiosIns
