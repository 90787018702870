import {
    sharedBacklinkProviders,
    sharedCountries,
    sharedCurrencies,
    sharedGenders,
    sharedGenres,
    sharedPaymentMethods
} from '@/api/shared'
import { fetchUsingTaxonomyDraftStack, fetchUsingTaxonomyPublicStack } from '@/api/using-taxonomy'

export default {
    state: {
        loading: false,
        statusSnackbar: false,
        colorMessage: 'default',
        messageDisabled: false,
        message: null,
        locales: [ 'en', 'de', 'fr', 'es', 'pt', 'ru' ],
        currencies: {},
        countries: [],
        genders: [],
        genres: [],
        paymentMethods: [],
        backlinkProviders: [],
        usingTaxonomy: {}
    },
    getters: {
        loading(state) {
            return state.loading
        },
        statusSnackbar(state) {
            return state.statusSnackbar
        },
        message(state) {
            return state.message
        },
        messageDisabled(state) {
            return state.messageDisabled
        },
        colorMessage(state) {
            return state.colorMessage
        },
        locales: state => state.locales,
        currencies: state => state.currencies,
        countries: state => state.countries,
        genders: state => state.genders,
        genres: state => state.genres,
        paymentMethods: state => state.paymentMethods,
        usingTaxonomy: state => state.usingTaxonomy,
        backlinkProviders: state => state.backlinkProviders
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setStatusSnackbar(state, flag) {
            if (!flag) {
                state.message = null
                state.colorMessage = 'default'
            }

            state.statusSnackbar = flag
        },
        setMessage(state, message) {
            state.message = message
        },
        setMessageDisabled(state, flag) {
            state.messageDisabled = flag
        },
        setColorMessage(state, color) {
            state.colorMessage = color
        },
        SET_CURRENCIES(state, items) {
            state.currencies = items
        },
        SET_COUNTRIES(state, items) {
            state.countries = items
        },
        SET_GENDERS(state, items) {
            state.genders = items
        },
        SET_GENRES(state, items) {
            state.genres = items
        },
        SET_PAYMENT_METHODS(state, items) {
            state.paymentMethods = items
        },
        SET_USING_TAXONOMY(state, items) {
            state.usingTaxonomy = items
        },
        SET_BACKLINK_PROVIDERS(state, items) {
            state.backlinkProviders = items
        }
    },
    actions: {
        setLoading(context, payload) {
            context.commit('setLoading', payload)
        },
        setMessage(context, message) {
            context.commit('setMessage', message)
            context.commit('setColorMessage', 'default')
            context.commit('setStatusSnackbar', true)
        },
        setErrorMessage(context, message) {
            context.commit('setMessage', message)
            context.commit('setColorMessage', 'error')
            context.commit('setStatusSnackbar', true)
        },
        fetchCurrencies({ commit }) {
            sharedCurrencies()
                .then(response => {
                    commit('SET_CURRENCIES', response.data.items)
                })
        },
        fetchCountries({ commit }) {
            sharedCountries()
                .then(response => {
                    commit('SET_COUNTRIES', response.data.items)
                })
        },
        fetchGenders({ commit }) {
            sharedGenders()
                .then(response => {
                    commit('SET_GENDERS', response.data.items)
                })
        },
        fetchGenres({ commit }) {
            sharedGenres()
                .then(response => {
                    commit('SET_GENRES', response.data.items)
                })
        },
        fetchPaymentMethods({ commit }) {
            sharedPaymentMethods()
                .then(response => {
                    commit('SET_PAYMENT_METHODS', response.data.items)
                })
        },
        async fetchBacklinkProviders({ commit }) {
            try {
                const response = await sharedBacklinkProviders()
                commit('SET_BACKLINK_PROVIDERS', response.data.items)
            } catch (err) {
                console.log(err)
            }
        },
        async fetchUsingTaxonomy({ commit }) {
            try {
                const publicStack = (await fetchUsingTaxonomyPublicStack()).data
                const draftStack = (await fetchUsingTaxonomyDraftStack()).data

                commit('SET_USING_TAXONOMY', Object.assign(publicStack, draftStack))
            } catch (err) {
                console.log(err)
            }
        }
    }
}
