import request from '@/tools/request'

export const getAllTagsRequest = () => {
    return request({
        method: 'GET',
        url: `tags`
    });
};

export const createTagRequest = (data) => {
    return request({
        method: 'POST',
        url: `tags/`,
        data
    });
};

export const updateTagRequest = (data, id) => {
    return request({
        method: 'PATCH',
        url: `tags/${id}`,
        data
    });
};

export const deleteTagRequest = (id) => {
    return request({
        method: 'DELETE',
        url: `tags/${id}`
    });
};
