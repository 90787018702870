import Vue from 'vue'
import store from '../store'

/**
 *
 * @param {Object} entity
 * @param {string} path
 * @param {*} defaultValue
 *
 * @return {*}
 */
export const translateEntity = (entity, path, defaultValue) => {
    defaultValue = defaultValue || path

    if (typeof entity !== 'object' || Array.isArray(entity)) {
        console.warn('Translate Entity: [entity] must be an object')

        return defaultValue
    }

    const translate = entity.translate

    if (typeof translate !== 'object' || Array.isArray(translate)) return defaultValue

    if (typeof path !== 'string') {
        console.warn('Translate Entity: [path] must be a string')

        return defaultValue
    }

    const locale = store.getters.locale
    const pathSplit = path.split('.')

    pathSplit.push(locale)

    let findingTranslate = translate[pathSplit[0]]

    if(pathSplit.length === 1) {
        return findingTranslate || defaultValue
    }

    for (let i = 1; i < pathSplit.length; i++) {
        try {
            findingTranslate = findingTranslate[pathSplit[i]]
        } catch (err) {
            // console.warn(err.message)
            break
        }
    }

    return findingTranslate || defaultValue
}

const TranslateEntityPlugin = {
    install(Vue) {
        Vue.prototype.$translateEntity = translateEntity
    }
}

Vue.use(TranslateEntityPlugin)
