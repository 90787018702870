// Publication statuses
export const PUBLICATION_UNSAVED = 'unsaved';
export const PUBLICATION_SAVED_AS_EDITOR_DRAFT = 'saved-as-editor-draft';
export const PUBLICATION_SAVED_AS_AUTHOR_DRAFT = 'saved-as-author-draft';
export const PUBLICATION_PENDING_REVIEW = 'pending-review';
export const PUBLICATION_RETURNED_FOR_REVISION = 'returned-for-revision';
export const PUBLICATION_PUBLISHED = 'published';
export const PUBLICATION_UNPUBLISHED = 'unpublished';

// Publication alert colors
export const ALERT_COLOR_UNSAVED = '#D33737';
export const ALERT_COLOR_SAVED_AS_DRAFT = '#C6B309';
export const ALERT_COLOR_PENDING_REVIEW = '#D68A1A';
export const ALERT_COLOR_RETURNED_FOR_REVISION = '#D337A8';
export const ALERT_COLOR_PUBLISHED = '#18C033';
export const ALERT_COLOR_UNPUBLISHED = '#F05E6F';

// Publication alert icons
export const ALERT_ICON_UNSAVED = 'mdi-close-circle';
export const ALERT_ICON_SAVED_AS_DRAFT = 'mdi-content-save';
export const ALERT_ICON_PENDING_REVIEW = 'mdi-text-box-search';
export const ALERT_ICON_RETURNED_FOR_REVISION = 'mdi-arrow-left-circle';
export const ALERT_ICON_PUBLISHED = 'mdi-check-circle';
export const ALERT_ICON_UNPUBLISH = 'mdi-text-box-remove';

// Publication alert text
export const ALERT_TEXT_UNSAVED = 'Unsaved';
export const ALERT_TEXT_SAVED_AS_DRAFT = 'Saved as draft';
export const ALERT_TEXT_PENDING_REVIEW = 'Pending review';
export const ALERT_TEXT_RETURNED_FOR_REVISION = 'Returned for revision';
export const ALERT_TEXT_PUBLISHED = 'Published';
export const ALERT_TEXT_UNPUBLISH = 'Unpublished';
