import request from '@/tools/request'

const prefix = 'shared'

/**
 * @returns {Promise}
 */
export const sharedCurrencies = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/currencies`,
    })
}

/**
 * @returns {Promise}
 */
export const sharedCountries = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/countries`,
    })
}

/**
 * @returns {Promise}
 */
export const sharedGenders = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/genders`,
    })
}

export const sharedGenres = () => {
    return request({
        method: 'GET',
        url: `${prefix}/genres`
    })
}

/**
 * @returns {Promise}
 */
export const sharedPaymentMethods = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/payment-methods`,
    })
}

/**
 * @returns {Promise}
 */
export const sharedLanguages = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/languages`,
    })
}

/**
 * @returns {Promise}
 */
export const sharedBacklinkProviders = () => {
    return request({
        method: 'GET',
        url: `${ prefix }/backlink-providers`,
    })
}

