import request from '@/tools/request'

const prefix = `auth`


export const authLogin = (payload) => {
	return request({
		method: 'POST',
		url: `${prefix}/sign-in`,
		data: payload
	})
}

export const authLogout = (data) => {
	return request({
		method: 'POST',
		url: `${prefix}/sign-out`,
		data
	})
}

export const getAuthUser = () => {
	return request({
		method: 'GET',
		url: `${prefix}/user`
	})
}

export const updateAuthUser = (data) => {
	return request({
		method: 'PATCH',
		url: `${prefix}/user`,
		data
	})
}

export const deleteAuthUser = () => {
	return request({
		method: 'DELETE',
		url: `${prefix}/user`
	})
}
