import request from '@/tools/request'
import api from "../config/api";
// import store from "@/store"

const prefix = 'articles'

export const getArticlePublicPath = (slug) => {
    return `${ api.MEDIA_FRONTEND_URL }/articles/${ slug }`
}

export const getArticleRequest = (postId) => {
    return request({
        method: 'GET',
        url: `${prefix}/${postId}`
    });
};

export const getArticlesQuantity = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}/quantity`,
        params
    });
};

export const getAllArticlesRequest = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}`,
        params
    });
};

export const getArticlesCollection = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}/collection`,
        params
    });
};

export const createArticleRequest = (data, options) => {
    return request({
        method: 'POST',
        url: `${prefix}/`,
        data,
        ...options
    });
};

export const updateArticleRequest = (postId, data, options) => {
    return request({
        method: 'PATCH',
        url: `${prefix}/${postId}`,
        ...options,
        data
    });
};

export const deleteArticleRequest = (id, options) =>  {
    return request({
        method: 'DELETE',
        url: `${prefix}/${id}`,
        ...options
    });
};
