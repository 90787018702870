import request from '@/tools/request'

const prefix = 'articles-types'

export const getArticleTypesCollection = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}`,
        params
    });
};


export const getArticleType = (typeId) => {
    return request({
        method: 'GET',
        url: `${prefix}/${typeId}`
    });
};

export const getArticleTypesQuantity = (params) => {
    return request({
        method: 'GET',
        url: `${prefix}/quantity`,
        params
    });
};

export const createArticleType = (data) => {
    return request({
        method: 'POST',
        url: `${prefix}/`,
        data
    });
};

export const updateArticleType = (typeId, data) => {
    return request({
        method: 'PATCH',
        url: `${prefix}/${typeId}`,
        data
    });
};

export const deleteArticleType = (id) =>  {
    return request({
        method: 'DELETE',
        url: `${prefix}/${id}`
    });
};
