import token from '@/tools/token'
import store from '@/store'

export default async (to, from, next) => {
    const isToken = !!token.getAccessToken()
    const whiteList = ['Login'] // no redirect whitelist
    const isWhiteList = whiteList.includes(to.name)

    document.title = to.meta?.title ?? 'Media Backoffice'

    // determine whether the user has logged in
    try {
        if (isToken) {
            if (!store.getters.user) {
                await store.dispatch('fetchAuthUser')

                if(isWhiteList) {
                    next({name: 'Dashboard'})
                } else {
                    next()
                }
            } else {
                if(isWhiteList) {
                    next({name: 'Dashboard'})
                } else {
                    next()
                }
            }
        } else {
            if(!isWhiteList) {
                next({name: 'Login'})
            } else {
                next()
            }
        }
    } catch (e) {
        if(!isWhiteList) {
            next({name: 'Login'})
        } else {
            next()
        }
    }
}
