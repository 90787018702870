import {
    getAllArticlesRequest,
    createArticleRequest,
    updateArticleRequest,
    deleteArticleRequest,
} from '@/api/articles';
import {
    createArticleSummaryRequest,
    deleteArticleSummaryRequest,
    updateArticleSummaryRequest
} from '@/api/articles-summary'

export default {
    state: {
        articles: [],
    },
    getters: {
        articles(state) {
            return state.articles;
        },
    },
    mutations: {
        setArticles(state, payload) {
            state.articles = payload;
        },
        addArticle(state, payload) {
            state.articles.push(payload);
        },
        delArticle(state, payload) {
            state.articles = state.articles.filter(article => article.postId !== payload);
        },
    },
    actions: {
        // Articles actions begins
        async fetchArticles ({commit}) {
            commit('setLoading', true);
            try {
                const res = await getAllArticlesRequest();
                commit('setArticles', res.data.reverse());
            } catch (error) {
                console.log(error);
            }
            commit('setLoading', false);
        },
        async createArticle ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await createArticleRequest(payload);
                commit('addArticle', res.data.data);

                // Create summary variant
                payload.commentsQuantity = 0
                await createArticleSummaryRequest(payload)

                await dispatch('deleteArticleRaw', payload.postId)
                await dispatch('setMessage', res.data.message);

            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async updateArticle ({commit, dispatch}, payload) {
            commit('setLoading', true);
            try {
                const res = await updateArticleRequest(payload, payload.postId);

                // Update summary variant
                await updateArticleSummaryRequest(payload.postId, {
                    slug: payload.slug ,
                    title: payload.title ,
                    rubric: payload.rubric ,
                    excerpt: payload.excerpt ,
                    ageRestrictions: payload.ageRestrictions ,
                    publicationStatus: payload.publicationStatus
                })

                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        async deleteArticle ({commit, dispatch}, id) {
            commit('setLoading', true);
            try {
                const res = await deleteArticleRequest(id);

                // Delete summary variant
                await deleteArticleSummaryRequest(id)

                commit('delArticle', id);
                dispatch('setMessage', res.data.message);
            } catch (error) {
                dispatch('setMessage', error.response.data.message);
            }
            commit('setLoading', false);
        },
        // Articles actions ends
    },
}
